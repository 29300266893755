import _ from 'lodash'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import {
  REPORTS_URL,
  REPORTS_URL_V1_1,
  SCORE_URL,
  REPORTS_CES_V1_1,
} from '~/assets/variables/endpoints'

export const state = () => ({
  agent_online_status: {
    status: 'loading',
    data: {
      online: 0,
      offline: 0,
    },
  },
  conversation_status: {
    status: 'loading',
    data: {
      unassigned: 0,
      campaign: 0,
      assigned: 0,
      resolved: 0,
    },
  },
  speed: {
    status: 'loading',
    data: {
      agent_first_response_time: '00:00:00',
      agent_response_time: '00:00:00',
      resolution_time: '00:00:00',
      // NOTE:
      // disabled for temporay, will be use in the future
      // wait_time: '00:00:00',
      // resolved_conversation: 0,
      // messages_sent: 0,
      // conversation_assigned: 0
    },
  },
  wilson_score: {
    status: 'loading',
    data: [],
    pagination: {
      offset: 1,
      limit: 7,
      total: 0,
    },
    query: '',
  },
  agent_score: {
    status: 'loading',
    data: [],
    total: 0,
    // pagination: {
    //   offset: 1,
    //   limit: 7,
    //   total: 0
    // },
    query: '',
  },
  agent_performance_comparison: {
    status: 'loading',
    data: [],
  },
  user_online_status: {
    status: 'loading',
    data: [],
  },
  hand_over_conversation: {
    status: 'loading',
    data: [],
  },
  on_going_conversation: {
    status: 'loading',
    data: [],
  },
})

export const getters = {
  getField,
}

export const actions = {
  exportReportData({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      let reportsUrl = REPORTS_URL
      const paramsType =
        params.type === 'conversation-status' ? 'inbox' : params.type
      const paramsPath =
        params.type === 'conversation-status' ||
        params.type === 'agent-online-status'
          ? 'agent-performance/'
          : params.path
      if (
        params.type === 'agent-performance-comparison' ||
        params.type === 'on-going-conversation' ||
        params.type === 'speed'
      )
        reportsUrl = REPORTS_URL_V1_1
      requests.whatsapp
        .getService(
          `${reportsUrl}/${paramsPath}${paramsType}/export`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                let filename = `report_${paramsType}`
                if (params.parameter.time_offsets !== undefined)
                  filename =
                    filename + `_timezone-${params.parameter.time_offsets}`
                if (params.parameter.channel)
                  filename = filename + `_channel-${params.parameter.channel}`
                filename = filename + `_${moment().format('DD-MMM-YYYY_HH:mm')}`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename.toUpperCase())
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to get data. Please try again later' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  exportReportDataCsatCes({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      let scoreEndpoint = SCORE_URL[params.url]
      if (params.url === 'ces') scoreEndpoint = REPORTS_CES_V1_1
      requests.whatsapp
        .getService(
          `${scoreEndpoint}/${params.type.replace('_', '-')}/export`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                let filename = `top_rated_agents_by_${params.url}`
                if (params.parameter.time_offsets !== undefined)
                  filename =
                    filename + `_timezone-${params.parameter.time_offsets}`
                if (params.parameter.channel)
                  filename = filename + `_channel-${params.parameter.channel}`
                filename = filename + `_${moment().format('DD-MMM-YYYY_HH:mm')}`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename.toUpperCase())
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to get data. Please try again later' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getReportDataMaster({ commit }, params) {
    return new Promise((resolve, reject) => {
      let reportsUrl = REPORTS_URL
      const paramsType =
        params.type === 'conversation-status' ? 'inbox' : params.type
      if (
        params.type === 'agent-performance-comparison' ||
        params.type === 'on-going-conversation' ||
        params.type === 'speed'
      )
        reportsUrl = REPORTS_URL_V1_1
      requests.whatsapp
        .getService(
          `${reportsUrl}/${params.path}${paramsType}`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              `UPDATE_GENERAL_${paramsType
                .toUpperCase()
                .replace(/-/g, '_')}_REPORT`,
              res
            )
            if (params.withMeta)
              commit(
                'layouts/SET_META',
                {
                  pagination: {
                    cursor: {},
                    limit: 6,
                    offset: 1,
                    total: res.data.length,
                  },
                },
                { root: true }
              )
            resolve(true)
          },
          (err) => {
            commit(`UPDATE_REPORT_STATUS`, {
              res: err,
              type: `${params.type.replace(/-/g, '_')}`,
            })
            reject(err)
          }
        )
    })
  },
  getReportDataMasterCsatCes({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const isCounted = params.parameter.offset === 1
      const sentParams = {
        query: state[params.type].query,
        is_counted: isCounted,
        ...params.parameter,
      }
      let scoreEndpoint = SCORE_URL[params.url]
      if (params.url === 'ces') scoreEndpoint = REPORTS_CES_V1_1
      requests.whatsapp
        .getService(
          `${scoreEndpoint}/${params.type.replace('_', '-')}`,
          sentParams,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              `UPDATE_GENERAL_${params.type.toUpperCase().replace(/-/g, '_')}`,
              { ...res, isCounted, query: sentParams.query }
            )
            if (params.withMeta)
              commit(
                'layouts/SET_META',
                {
                  pagination: {
                    cursor: {},
                    limit: 6,
                    offset: 1,
                    total: res.data.length,
                  },
                },
                { root: true }
              )
            resolve(true)
          },
          (err) => {
            commit(`UPDATE_REPORT_STATUS`, { res: err, type: params.type })
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_GENERAL_AGENT_ONLINE_STATUS_REPORT(state, payload) {
    state.agent_online_status.data = payload.data
    state.agent_online_status.status = payload.status
  },
  UPDATE_GENERAL_INBOX_REPORT(state, payload) {
    state.conversation_status.data = payload.data
    state.conversation_status.status = payload.status
  },
  UPDATE_GENERAL_SPEED_REPORT(state, payload) {
    if (payload.data.length > 0) {
      const result = []
      for (const item of Object.keys(payload.data[0])) {
        result.push({
          key: `agent_${item}`,
          name: `agent ${item.replace(/_/g, ' ')}`,
          value: payload.data[0][item],
        })
      }
      state.speed.data = result.filter((_data, index) => index < 3)
    } else {
      state.speed.data = [
        {
          key: 'agent_first_response_time',
          name: 'agent first response time',
          value: '00:00:00',
        },
        {
          key: 'agent_response_time',
          name: 'agent response time',
          value: '00:00:00',
        },
        {
          key: 'agent_resolution_time',
          name: 'agent resolution time',
          value: '00:00:00',
        },
        // NOTE:
        // disabled for temporay, will be use in the future
        // {
        //   key: 'wait_time',
        //   name: 'wait time',
        //   value: '00:00:00'
        // },
        // {
        //   key: 'resolved_conversation',
        //   name: 'resolved conversation',
        //   value: 0
        // },
        // {
        //   key: 'messages_sent',
        //   name: 'messages sent',
        //   value: 0
        // },
        // {
        //   key: 'conversation_assigned',
        //   name: 'conversation assigned',
        //   value: 0
        // }
      ]
    }
    state.speed.status = payload.status
  },
  UPDATE_GENERAL_AGENT_PERFORMANCE_COMPARISON_REPORT(state, payload) {
    state.agent_performance_comparison.data = _.orderBy(
      payload.data,
      ['full_name'],
      ['asc']
    )
    state.agent_performance_comparison.status = payload.status
  },
  UPDATE_GENERAL_USER_ONLINE_STATUS_REPORT(state, payload) {
    state.user_online_status.data = _.orderBy(
      payload.data,
      ['last_changed_at'],
      ['desc']
    )
    state.user_online_status.status = payload.status
  },
  UPDATE_GENERAL_HAND_OVER_CONVERSATION_REPORT(state, payload) {
    state.hand_over_conversation.data = _.orderBy(
      payload.data,
      ['date'],
      ['desc']
    )
    state.hand_over_conversation.status = payload.status
  },
  UPDATE_GENERAL_ON_GOING_CONVERSATION_REPORT(state, payload) {
    state.on_going_conversation.data = _.orderBy(
      payload.data,
      ['last_login'],
      ['desc']
    )
    state.on_going_conversation.status = payload.status
  },
  UPDATE_GENERAL_WILSON_SCORE(state, payload) {
    state.wilson_score.status = payload.status
    state.wilson_score.data = payload.data.response
    state.wilson_score.total = payload.data.total
    state.wilson_score.query = payload.query
    if (payload.isCounted) {
      state.wilson_score.pagination = payload.data.pagination
    } else {
      state.wilson_score.pagination = {
        ...state.wilson_score.pagination,
        offset: payload.data.pagination.offset,
        limit: payload.data.pagination.limit,
      }
    }
  },
  UPDATE_GENERAL_AGENT_SCORE(state, payload) {
    state.agent_score.status = payload.status
    state.agent_score.data = payload.data.response
    state.agent_score.query = payload.query
    state.agent_score.total = payload.data.total
    // if (payload.isCounted) {
    //   state.agent_score.pagination = payload.data.pagination
    // } else {
    //   state.agent_score.pagination = {
    //     ...state.agent_score.pagination,
    //     offset: payload.data.pagination.offset,
    //     limit: payload.data.pagination.limit
    //   }
    // }
  },
  UPDATE_REPORT_STATUS(state, payload) {
    state[payload.type].status = payload.res.status
    state[payload.type].error = payload.res?.error?.messages[0] ?? ''
  },
}
