import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { REPORTS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  isLoadingBalance: false,
  responseBalance: [],
  isLoadingBasic: false,
  responseBasic: [],
})

export const getters = {
  getField,
}

export const actions = {
  getAdditionalPackage({ commit }, params) {
    commit('UPDATE_LOADING_BALANCE', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/billing/additional-balance`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA_BALANCE', res.data)
            commit('UPDATE_LOADING_BALANCE', false)
            resolve(true)
          },
          (err) => {
            resolve(0)
            commit('UPDATE_LOADING_BALANCE', false)
            reject(err)
          }
        )
    })
  },
  getBasicPackage({ commit }, params) {
    commit('UPDATE_LOADING_BASIC', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/billing/usage`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA_BASIC', res.data)
            commit('UPDATE_LOADING_BASIC', false)
            resolve(true)
          },
          (err) => {
            resolve(0)
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_LOADING_BALANCE(state, payload) {
    state.isLoadingBalance = payload
  },
  UPDATE_DATA_BALANCE(state, payload) {
    state.responseBalance = payload
  },
  UPDATE_LOADING_BASIC(state, payload) {
    state.isLoadingBasic = payload
  },
  UPDATE_DATA_BASIC(state, payload) {
    state.responseBasic = payload
  },
}
