import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import { REPORTS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  subMenusReport: [
    { title: 'General', slug: 'general', status: false, parent: 'reports' },
    {
      title: 'Agent Performance',
      slug: 'agent_performance',
      status: false,
      parent: 'reports',
    },
    { title: 'Broadcast', slug: 'broadcast', status: false, parent: 'reports' },
    { title: 'CSAT', slug: 'csat', status: false, parent: 'reports' },
    { title: 'CES', slug: 'ces', status: false, parent: 'reports' },
    { title: 'NPS', slug: 'nps', status: false, parent: 'reports' },
  ],
  inboxReports: {
    unassigned: 0,
    assigned_not_replied: 0,
    assigned: 0,
    resolved: 0,
  },
  speedReports: {
    first_response: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    response_time: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    resolution_time: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    wait_time: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  },
})

export const getters = {
  getField,
  getSubMenusReport(state) {
    // state.subMenusReport
    return _.filter(state.subMenusReport, ['status', true])
  },
}

export const actions = {
  getInboxReports({ commit }) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(
        `${REPORTS_URL}/inbox`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_INBOX_REPORT', res.data)
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getSpeedOfResponseReports({ commit }) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .getService(
        `${REPORTS_URL}/speed`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_SPEED_OF_RESPONSE_REPORT', res.data)
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
}

export const mutations = {
  updateField,
  UPDATE_INBOX_REPORT(state, payload) {
    state.inboxReports = payload
  },
  UPDATE_SPEED_OF_RESPONSE_REPORT(state, payload) {
    state.speedReports = payload
  },
  UPDATE_SUBMENUS_REPORT(state, payload) {
    state.subMenusReport[payload.i].status = payload.status
  },
}
