// import _ from 'lodash'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { REPORTS_CES, REPORTS_CES_V1_1 } from '~/assets/variables/endpoints'

export const state = () => ({
  cesBreakdown: {
    status: 'loading',
    average: null,
    total_response: null,
    data: [],
    query: '',
  },
  cesResponses: {
    status: 'loading',
    data: [],
    pagination: {
      offset: 1,
      limit: 7,
      total: 0,
    },
    query: '',
  },
})

export const getters = {
  getField,
}

export const actions = {
  exportReportData({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      requests.whatsapp
        .getService(`${REPORTS_CES}/${params.type}/export`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                let filename = `report_ces_${params.type}`
                if (params.parameter.time_offsets !== undefined)
                  filename =
                    filename + `_timezone-${params.parameter.time_offsets}`
                if (params.parameter.channel)
                  filename = filename + `_channel-${params.parameter.channel}`
                filename = filename + `_${moment().format('DD-MMM-YYYY_HH:mm')}`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename.toUpperCase())
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to get data. Please try again later' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  exportReportResponse({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      requests.whatsapp
        .getService(
          `${REPORTS_CES_V1_1}/${params.type}/export`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                let filename = `report_ces_${params.type}`
                if (params.parameter.time_offsets !== undefined)
                  filename =
                    filename + `_timezone-${params.parameter.time_offsets}`
                if (params.parameter.channel)
                  filename = filename + `_channel-${params.parameter.channel}`
                filename = filename + `_${moment().format('DD-MMM-YYYY_HH:mm')}`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename.toUpperCase())
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to get data. Please try again later' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getReportDataMaster({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const isCounted = params.parameter.offset === 1
      const sentParams = {
        query: state[params.state].query,
        is_counted: isCounted,
        ...params.parameter,
      }
      requests.whatsapp
        .getService(`${REPORTS_CES}/${params.type}`, sentParams, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(`UPDATE_${params.type.toUpperCase().replace(/-/g, '_')}`, {
              ...res,
              isCounted,
              query: sentParams.query,
            })
            if (params.withMeta)
              commit(
                'layouts/SET_META',
                {
                  pagination: {
                    cursor: {},
                    limit: 6,
                    offset: 1,
                    total: res.data.length,
                  },
                },
                { root: true }
              )
            resolve(true)
          },
          (err) => {
            commit(`UPDATE_REPORT_STATUS`, { res: err, type: params.state })
            reject(err)
          }
        )
    })
  },
  getReportDataResponse({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const isCounted = params.parameter.offset === 1
      const sentParams = {
        query: state[params.state].query,
        is_counted: isCounted,
        ...params.parameter,
      }
      requests.whatsapp
        .getService(`${REPORTS_CES_V1_1}/${params.type}`, sentParams, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(`UPDATE_${params.type.toUpperCase().replace(/-/g, '_')}`, {
              ...res,
              isCounted,
              query: sentParams.query,
            })
            if (params.withMeta)
              commit(
                'layouts/SET_META',
                {
                  pagination: {
                    cursor: {},
                    limit: 6,
                    offset: 1,
                    total: res.data.length,
                  },
                },
                { root: true }
              )
            resolve(true)
          },
          (err) => {
            commit(`UPDATE_REPORT_STATUS`, { res: err, type: params.state })
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_BREAKDOWN(state, payload) {
    if (payload.data.total_response) {
      state.cesBreakdown.status = payload.status
      state.cesBreakdown.average = payload.data.average
      state.cesBreakdown.total_response = payload.data.total_response
      state.cesBreakdown.data = [
        payload.data.easy,
        payload.data.neutral,
        payload.data.difficult,
      ]
    } else {
      state.cesBreakdown.status = payload.status
      state.cesBreakdown.average = payload.data.average
      state.cesBreakdown.total_response = payload.data.total_response
      state.cesBreakdown.data = []
    }
  },
  UPDATE_METRIC_RESPONSE(state, payload) {
    state.cesResponses.status = payload.status
    state.cesResponses.data = payload.data.response
    state.cesResponses.query = payload.query

    if (payload.isCounted) {
      state.cesResponses.pagination = payload.data.pagination
    } else {
      state.cesResponses.pagination = {
        ...state.cesResponses.pagination,
        offset: payload.data.pagination.offset,
        limit: payload.data.pagination.limit,
      }
    }
  },
  UPDATE_REPORT_STATUS(state, payload) {
    if (payload.res.status === 'error') {
      if (payload.res.error.messages[0] === 'breakdown data empty') {
        state[payload.type].status = 'success'
        return (state[payload.type].data = [])
      }
      return (state[payload.type].status = payload.res.status)
    }
    state[payload.type].status = payload.res.status
  },
}
