import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { REPORTS_URL, REPORTS_URL_V1_1 } from '~/assets/variables/endpoints'

export const state = () => ({
  total_message: {
    status: 'loading',
    data: {},
  },
  total_reply: {
    status: 'loading',
    data: {},
  },
  broadcast_log: {
    status: 'loading',
    data: [],
  },
  broadcast_data: {
    status: 'loading',
    data: [],
    pagination: {
      offset: 1,
      limit: 7,
      total: 0,
    },
    query: '',
  },
})

export const getters = {
  getField,
}

export const actions = {
  exportReportData({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      let endpointUrl = REPORTS_URL
      if (params.type === 'total-reply' || params.type === 'broadcast-log')
        endpointUrl = REPORTS_URL_V1_1
      requests.whatsapp
        .getService(
          `${endpointUrl}/campaign/${params.type}/export`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                let filename = `report_${params.type}`
                if (params.parameter.time_offsets !== undefined)
                  filename =
                    filename + `_timezone-${params.parameter.time_offsets}`
                if (params.parameter.channel)
                  filename = filename + `_channel-${params.parameter.channel}`
                filename = filename + `_${moment().format('DD-MMM-YYYY_HH:mm')}`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename.toUpperCase())
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to get data. Please try again later' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getReportDataMaster({ commit }, params) {
    return new Promise((resolve, reject) => {
      const isCounted = params.parameter.offset === 1
      const parameter = {
        ...params.parameter,
        is_counted: isCounted,
      }
      let endpointUrl = REPORTS_URL
      if (params.type === 'total-reply' || params.type === 'broadcast-log')
        endpointUrl = REPORTS_URL_V1_1
      requests.whatsapp
        .getService(`${endpointUrl}/campaign/${params.type}`, parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              `UPDATE_GENERAL_${params.type
                .toUpperCase()
                .replace(/-/g, '_')}_REPORT`,
              { ...res, isCounted }
            )
            if (params.withMeta)
              commit(
                'layouts/SET_META',
                {
                  pagination: {
                    cursor: {},
                    limit: 7,
                    offset: 1,
                    total: res.data.response.length,
                  },
                },
                { root: true }
              )
            resolve(true)
          },
          (err) => {
            commit(`UPDATE_REPORT_STATUS`, {
              res: err,
              type: `${params.type.replace(/-/g, '_')}`,
            })
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_GENERAL_AGENT_ONLINE_STATUS_REPORT(state, payload) {
    state.agent_online_status.data = payload.data
    state.agent_online_status.status = payload.status
  },
  UPDATE_GENERAL_TOTAL_MESSAGE_REPORT(state, payload) {
    state.total_message.data = payload.data
    state.total_message.status = payload.status
  },
  UPDATE_GENERAL_TOTAL_REPLY_REPORT(state, payload) {
    state.total_reply.data = payload.data
    state.total_reply.status = payload.status
  },
  UPDATE_GENERAL_BROADCAST_LOG_REPORT(state, payload) {
    const data = payload.data.sort(
      (left, right) =>
        moment(left.date).format('YYYYMMDD') -
        moment(right.date).format('YYYYMMDD')
    )
    state.broadcast_log.data = data
    state.broadcast_log.status = payload.status
  },
  UPDATE_GENERAL_BROADCAST_DATA_REPORT(state, payload) {
    state.broadcast_data.status = payload.status
    state.broadcast_data.data = payload.data.response
    state.broadcast_data.query = payload.query

    if (payload.isCounted) {
      state.broadcast_data.pagination = payload.data.pagination
    } else {
      state.broadcast_data.pagination = {
        ...state.broadcast_data.pagination,
        offset: payload.data.pagination.offset,
        limit: payload.data.pagination.limit,
      }
    }
  },
  UPDATE_REPORT_STATUS(state, payload) {
    state[payload.type].status = payload.res.status
  },
}
